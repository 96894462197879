.fontSize10vmin {
  font-size: 10vmin !important;
}

.dialpadMini .fontSize5vmin {
  font-size: 18px !important;
}

.dialpadMaxi .fontSize5vmin {
  font-size: 5vmin !important;
}

.fontSize5vmin div {
  overflow: hidden !important;
  text-overflow: clip !important;
}

.noPadding .dx-popup-content {
  padding: 0;
}

.dx-button-content {
  padding: 0;
}

.dx-tabpanel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dx-tabpanel .dx-tabpanel-container {
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.fullSize {
  width: 100%;
  height: 100%;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexWrap {
  flex-wrap: wrap;
}

.flex {
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.flexMini {
  flex: 0.1 2;
  min-height: 0;
  min-width: 0;
}

.vertDelimiter {
  width: 0.5em;
}

.horzDelimiter {
  height: 0.5em;
}

.marginTop {
  margin-top: 0.5em;
}

.marginBottom {
  margin-bottom: 0.5em;
}

.marginRight {
  margin-right: 0.5em;
}

.paddingTop {
  padding-top: 0.5em;
}

.paddingTopBig {
  padding-top: 1.5em;
}

.paddingBottom {
  padding-bottom: 0.5em;
}

.paddingMini {
  padding: 0.25em;
}

.padding {
  padding: 0.5em;
}

.noBackground {
  background-color: transparent;
}

.noUserSelect {
  user-select: none;
}

.listItem {
  padding: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.dx-swatch-dark .listItem,
.dx-swatch-dark_compact .listItem {
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}

.listItem:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.dx-swatch-dark .listItem:hover,
.dx-swatch-dark_compact .listItem:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.gridItem {
  padding: 0.5em;
}

.settings__tab-body .ant-divider {
  margin: 0 0 1em 0;
}

.settings__tab-body > h2 {
  margin-bottom: 2px;
  font-size: 1.25rem;
}

.settings__tab-body .ant-form-item .ant-form-item-label {
  padding-bottom: 4px;
}

.settings__tab-body .ant-form-item .ant-form-item-label > label {
  font-size: 0.75rem;
}

.json__textarea-container {
  min-height: 100%;
  margin-bottom: 0 !important;
}

.json__textarea-container,
.json__textarea-container *,
.json__textarea-container .json__textarea {
  height: 100%;
  align-items: stretch;
}

.json__textarea {
  resize: none;
}

.dx-swatch-dark .gridItem,
.dx-swatch-dark_compact .gridItem {
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}

.containerBorder {
  border-radius: 0.25em;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.24);
}

.dx-swatch-dark .containerBorder,
.dx-swatch-dark_compact .containerBorder {
  border-color: rgba(255, 255, 255, 0.24);
}

.containerHeader {
  background-color: rgba(0, 0, 0, 0.24);
  padding: 0.25em;
  text-align: center;
}

.dx-swatch-dark .containerHeader,
.dx-swatch-dark_compact .containerHeader {
  background-color: rgba(255, 255, 255, 0.24);
}

.middle {
  margin: auto;
}

.bottom {
  margin-top: auto;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.minWidth25 {
  min-width: 25%;
}

.videoCallHeight {
  height: 20em;
}

.maxWidth15 {
  max-width: 15%;
}

.minWidthYesNo {
  width: 30%;
  min-width: 6em;
  max-width: 10em;
}

.minHeight15em {
  min-height: 15em;
}

.scrollY {
  overflow-y: auto;
}

.hidden {
  display: none;
}

.video {
  height: 100%;
  max-width: 50%;
}

/*Global*/

.ant-btn-link > span {
  color: inherit;
  font-weight: 500;
  font-size: 0.688rem;
}

.ant-badge.ant-badge-status {
  display: flex;
  align-items: center;
}

p,
span {
  font-weight: 500;
}

.ant-badge-status-dot.ant-badge-status-success {
  box-shadow: 0px 0px 10px #00ff37;
}

.ant-badge-status-dot.ant-badge-status-error {
  box-shadow: 0px 0px 10px #ff0000;
}

.ant-badge-status-dot.ant-badge-status-warning {
  box-shadow: 0px 0px 10px #ffe600;
}

.tag-round.ant-tag {
  border-radius: 6px;
  font-weight: 500;
}

.search.ant-input-affix-wrapper {
  border-radius: 12px;
  border: 2px solid #449fdb;
}

.search.ant-input-affix-wrapper:hover {
  z-index: unset !important;
  border-right-width: 2px !important;
}

.search input {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

.search svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.ant-list-items > .ant-btn:hover {
  background-color: #3174e60d;
}

.list__right-content {
  font-size: 0.625rem;
  color: #74889b;
  align-items: flex-end;
}

.list .ant-list-item {
  gap: 16px;
  flex-wrap: nowrap;
}

.list .list__right-content {
  overflow: hidden;
}

.list .list__right-content > div,
.list .ant-list-item-meta-content * {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.list .ant-list-item-meta * {
  display: inline-block;
}

@media (max-width: 959.99px) {
  .gridItem {
    padding: 0;
  }
}
