/*Softphone.tsx*/

.phone-container {
  border-radius: 16px;
  box-shadow: 15px 15px 50px 0px #0000001a;
  width: 310px;
  box-sizing: border-box;
  /* overflow: hidden; */
  position: relative;
}

.phone {
  padding: 14px 14px 26px 14px;
  background-color: #ecf1f3;
  position: relative;
}

.phone .ant-btn-round.ant-btn {
  border-radius: 12px;
  height: auto;
}

.phone .phone__header {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.phone .phone__header__left {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.phone .phone__headwer__left .ant-btn:hover,
.phone .phone__header__left .ant-btn:focus-visible {
  border-color: #40a9ff;
}

.phone__header__left span.ant-badge-status-text {
  font-size: 0.563rem;
}

button.phone__header__right {
  font-weight: 500;
  display: flex;
  height: auto;
  padding-right: 0;
}

.phone button.phone__notifications-button {
  padding: 8px;
  color: #74889b;
  border: 1px solid rgba(64, 169, 255, 0.3);
  box-shadow: 0px 8px 15px rgba(49, 116, 230, 0.05);
  margin-right: 12px;
}

.phone button.phone__notifications-button:hover {
  border-color: #40a9ff;
}

.phone button.phone__notifications-button.active {
  background: #40a9ff;
  border: 1px solid #40a9ff4d;
  color: white;
}

.phone button.phone__notifications-button.active:focus-visible {
  border-color: #1e2a33;
}

.phone button.phone__notifications-button svg {
  width: 16px;
  height: 16px;
}

.phone__contacts-container {
  position: relative;
}

.phone .phone__controls {
  display: flex;
  gap: 6px;
  justify-content: stretch;
  margin-bottom: 14px;
}

.phone .phone__controls > .ant-btn {
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  flex: 1;
  justify-content: center;
  padding: 8px;
}

.phone .phone__controls > .ant-btn:enabled {
  border: 1px solid #40a9ff4d;
}

.phone .phone__controls .ant-btn .anticon {
  font-size: 0.875rem;
}

.phone .phone__controls .ant-btn:first-child .anticon {
  color: #40a9ff;
}

.phone .phone__controls .ant-btn:last-child .anticon {
  color: #f5222d;
}

.phone .phone__controls > .ant-btn:enabled:hover,
.phone .phone__controls > .ant-btn:focus-visible {
  border-color: #40a9ff;
  color: unset;
}

.phone .phone__number-input-container {
  margin-bottom: 6px;
}

.phone .phone__number-input {
  height: 44px;
}

.phone .phone__number-input svg {
  color: #1890ff;
}

/*Call controls*/

.call-controls {
  justify-content: stretch;
  gap: 10px;
  margin-bottom: 14px;
}

.call-controls > button.ant-btn {
  flex: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid transparent;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-controls > button.ant-btn:disabled {
  background-color: #e0e7eb;
}

.call-controls > button.ant-btn:disabled:hover {
  border: 1px solid transparent;
}

.call-controls > .ant-btn span {
  color: #fff;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1;
}

.call-controls > .answer-button,
.call-controls > .answer-button:focus {
  background-color: #52c41a;
  box-shadow: 0px 10px 20px 0px #52c41a4d;
}

.call-controls > .answer-button:hover {
  background-color: #49b613;
}

.call-controls > .answer-button:focus-visible {
  border: 1px solid #2a7c02;
}

.call-controls > .decline-button,
.call-controls > .decline-button:focus {
  background-color: #ff4d4f;
  box-shadow: 0px 10px 20px 0px #ff4d4f4d;
}

.call-controls > .decline-button:hover {
  background-color: #f54143;
}

.call-controls > .decline-button:focus-visible {
  border-color: #940204;
}
