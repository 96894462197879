/*Toggles*/

.phone__toggles {
  justify-content: space-between;
  gap: 8px;
}

.phone__toggles button.ant-btn {
  color: #74889b;
  padding: 10px;
  line-height: 0;
  border: 1px solid #f5f5f5;
  min-width: 40px;
  width: 40px;
}

.phone__toggles button.ant-btn:focus-visible {
  border-color: #0d92d0;
}

.phone__toggles button.ant-btn.active {
  color: #fff;
  background: #40a9ff;
}

.phone__toggles button.ant-btn:hover {
  border-color: #40a9ff;
}

.phone__toggles button.ant-btn:disabled:hover {
  border-color: #f5f5f5;
  color: #74889b;
}

.phone__toggles button.ant-btn svg {
  width: 18px;
  height: 18px;
}

.phone__dialpad .ant-btn,
.phone__toggles .ant-btn,
.phone__controls > .ant-btn {
  box-shadow: 0px 8px 15px 0px #3174e60d;
}
