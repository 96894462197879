/*Если вариативные шрифты не поддерживаются подключает обчные*/
@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: Montserrat;
    src: url('./fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: Montserrat;
    src: url('./fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: Montserrat;
    src: url('./fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: Montserrat;
    src: url('./fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
  }
}

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: Montserrat;
    src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype supports variations'),
      url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 400 700;
  }
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
h1,
h2,
h3,
h4 {
  color: #1e2a33;
  margin: 0;
}
