/*Buttons.jsx*/

.phone-button {
  width: 88px !important;
  height: 88px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: white;
}

.phone-button.success {
  background-color: #52c41a;
  border-color: #52c41a;
}
.phone-button.success:hover {
  background-color: #5fd326;
}
.phone-button.no-config {
  background-color: gray;
  color: white;
}
.phone-button.error {
  background-color: red;
}
.phone-button.initializing {
  background-color: yellow;
  color: black;
}

/* .phone-button .phone-button.ant-btn.ant-btn-primary {
  background-color: #52c41a;
  border-color: #52c41a;
}

.phone-button.ant-btn.ant-btn-primary:hover {
  background-color: #5fd326;
  border-color: #5fd326;
} */
