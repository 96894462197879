.active-call {
  padding: 14px;
  border-bottom: 1px solid #ecf1f3;
}

.active-call__heading-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 10px;
}

.active-call__heading {
  font-weight: 700;
  font-size: 1rem;
}

.signal {
  display: flex;
  gap: 2px;
  align-items: baseline;
}

.signal__block {
  width: 5px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 1px;
}

.signal__block.active {
  background: #fff;
}

.signal__block:nth-child(1) {
  height: 8px;
}

.signal__block:nth-child(2) {
  height: 12px;
}

.signal__block:nth-child(3) {
  height: 16px;
}

.active-call__caller-container {
  font-size: 0.75rem;
}

.active-call__caller-text {
  margin: 0 0 10px 0;
  display: flex;
  gap: 4px;
  align-items: center;
}

.active-call__caller-text > * {
  display: inline-block;
}

.active-call__caller-text > span {
  color: white;
}

.active-call__caller-text > span svg {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.active-call__caller-text > span:not(:last-child) {
  margin-right: 6px;
}

.active-call__caller-text > .active-call__caller-text__number {
  color: #74889b;
}

.active-call__timer {
  display: flex;
  line-height: 1;
  align-items: center;
}

.active-call__timer > span {
  color: #52c41a;
  font-size: 1rem;
  /*line-height: 1;*/
}

.active-call__timer.hold > span {
  color: #f5222d;
}

.ant-tag.active-call__hold-tag {
  margin-left: 8px;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0 6px;
  display: inline-flex;
  align-items: center;
}

.active-call__hold-tag > svg {
  color: #1e2a33;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.active-call__hold-tag span {
  color: #1e2a33;
}

.decline-button,
.answer-button,
.toggle-button,
.active {
  border: none !important;
}

.answer-button:hover {
  background-color: #49b613 !important;
}

.decline-button:hover {
  background-color: #eb4548 !important;
}
.phone__toggles .toggle-button .ant-btn {
  border-color: transparent;
}
